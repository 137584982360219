%button-tab:not(.button-icon-only) {
  border-radius: 0;
  
  &.button.button-standard {
    @extend %christopher;
    color: $clr_weiss;
    @extend %padding-narrow;
  }

  transition: $ease-main $speed-std;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: 0;
  border:1px dashed white;
  border-bottom: none;
  &:not(:first-of-type) {
    border-top-left-radius: 0 !important;
  }

  &:not(:last-of-type) {
    border-top-right-radius: 0 !important;
  }

  &[data-theme="not-active"],
  &[data-open="false"] {

    @extend %theme-mineshaft;

    &:hover {
      @extend %theme-alto;
    }
  }

  &[data-theme="is-active"],
  &[data-open="true"] {
    @extend %theme-mako;
    border: 0;
    &:hover {
      @extend %theme-alto;
    }
  }

  &.button-disabled {
    vertical-align: bottom;
  }
}
