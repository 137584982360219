@import '../../../scss/config/all';
@import 'base';
@import 'themes';
@import 'primary';
@import 'error';
@import 'secondary';
@import 'icon-only';
@import 'tab';

.button {
  @extend %button;
  svg,
  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.button-primary {
  @extend %button-primary;
}

.button-secondary {
  @extend %button-secondary;
}

.button-error {
  @extend %button-error;
}

.button-icon-only {
  @extend %button-icon-only;
}

.button-tab {
  @extend %button-tab;
  padding: 0.5rem;
}

// Disabled button styles (all !important to override anything)
.button-disabled {
  background-color: $clr_disabled-background !important;
  border-color: $clr_disabled-background !important;
  color: $clr_disabled-foreground !important;
  &:hover {
    cursor: not-allowed;
  }
}

form {
  fieldset {
    .button.button-secondary.button-small {
      text-transform: capitalize;
      margin-right: 10px;
    }
  }
}

