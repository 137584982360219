
@import './scss/config/all';
@import './components/@shared/input/form-message';
@import './components/@shared/input/base';
@import './scss/reset';

.input_container {
  display: block;
  position: relative;

  input,
  select,
  textarea {
    @extend %input;
    @extend %input-border;
    @extend %input-padding;
    @extend %richie;
    @extend %theme-mineshaft;
    @extend %border_radius-std;
    line-height: 1.5rem;
    display: block;
    width: 100%;
    box-shadow: none !important;
    transition: 0.5s;
    cursor: text;
    // &[data-pristine="true"] {}

    &:hover,
    &:focus {
      background-color: rgba(255,255,255,0.2);
    }

    &:disabled{
      opacity: 0.8;
      cursor: no-drop;
      &:hover,
      &:focus {
        background-color: rgba(255,0,0,0.1);
      }
    }


    // Removed temporarily as need to edit
    //&[data-pristine="false"] {
    //  &:invalid:not(:focus) {
    //    @extend %input-validation-failure;
    //  }
    //  &:valid:not(:focus) {
    //    @extend %input-validation-success;
    //  }
    //}
  }

  textarea {
    resize: none;
  }

  [class^="icon-"] {
    position: absolute;
    top: 1.5rem;
    right: -50000px;
    transform: $icon_translate scale(0);
    opacity: 0;
    transition: opacity $ease-main $speed-fast, transform $ease-main $speed-fast;

    &.icon-success {
      color: $clr_success;
    }

    &.icon-failure {
      color: $clr_failure;
    }
  }

}

.fancy-select {
  display: block;
  padding: 0;
  position: relative;
  z-index: 0;
  select{
    padding-right: 30px;
    background: #2f3137;
  }
  &:after {
    content: "▼";
    font-size: inherit;
    color: $clr_silverado;
    position: absolute;
    top: 50%;
    right: 0.5em;
    z-index: 0;
    transform: translateY(-50%);
  }
  option {
    color: $clr_nightshift;
  }

}

.status-td{
  text-transform: capitalize;
  color: black;
  text-align: center !important;
  &.status-registered{
    background-color: #ebedeb !important;
  }
  &.status-new-candidate{
    background-color: #ebedeb !important;
  }
  &.status-pre-screened{
    background-color: #bee6e0 !important;
  }
  &.status-enrolled{
    background-color: #ffcc58 !important;
  }
  &.status-screen-visit-booked{
    background-color: #9eac91 !important;
  }
  &.status-consented{
    background-color: #f5bac9 !important;
  }
}

html * {
  box-sizing: inherit;
   //outline: 1px solid rgba(255, 0, 0, 0.15);
}
