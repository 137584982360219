// Brand colors
$clr_faze-gull: #46494f;
$clr_faze-mako: #3d4049;
$clr_faze-beauty: #edbcc9;
$clr_faze-sienna: #e77b45;
$clr_faze-goldenrod: #f9cd6d;
$clr_faze-lochinvar: #349b91;
$clr_faze-skeptic: #c6e5e0;
$clr_faze-bud: #a1ab94;
$clr_faze-nurse: #ebedeb;
$clr_faze-mandy: #ec5a6c;
$clr_faze-athens: rgba(255, 255, 255, 0);
$clr_faze-alto: rgba(#d8d8d8, 0.06);
$clr_faze-otla: rgba(0, 0, 0, 0.12);

// Disabled elements
$clr_disabled-background: #eeeeee;
$clr_disabled-foreground: #666666;
