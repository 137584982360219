@import '../config/all';

.body_content {
  @extend %padding-horizontal-narrow;
  @extend %padding-vertical-narrow;
  @extend %theme-mineshaft;
}

nav+.body_content {
  padding-top: 0;
}
