$themes-grayscale: (
  "weiss": $clr_weiss,
  "moonlight": $clr_moonlight,
  "alabaster": $clr_alabaster,
  "silverado": $clr_silverado,
  "boulder": $clr_boulder,
  "tuatara": $clr_tuatara,
  "mineshaft": $clr_mineshaft,
  "nightshift": $clr_nightshift
);

$themes-utility: (
  "error": $clr_warning,
  "success": $clr_success,
  "warning": $clr_warning,
  "failure": $clr_failure,
  "neutral": $clr_neutral
);

$themes-brand: (
  "mako": $clr_faze-mako,
  "lochinvar": $clr_faze-lochinvar,
  "gull": $clr_faze-gull,
  "beauty": $clr_faze-beauty,
  "sienna": $clr_faze-sienna,
  "goldenrod": $clr_faze-goldenrod,
  "skeptic": $clr_faze-skeptic,
  "bud": $clr_faze-bud,
  "nurse": $clr_faze-nurse,
  "mandy": $clr_faze-mandy,
  "alto": $clr_faze-alto,
  "otla": $clr_faze-otla
);

$themes: map-merge(
  map-merge($themes-grayscale, $themes-utility)
, $themes-brand);

body{
    font-family: Roboto;
}

@import "grayscale/all";
@import "utility/all";
@import "brand/all";
