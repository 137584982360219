$border_radius-sml: 0.125rem;
$border_radius-std: 0.25rem;
$border_radius-med: 0.5rem;
$border_radius-lrg: 1rem;
$border_radius-lozenge: 500rem;

%border_radius {
  background-clip: padding-box; // stops bg color from leaking outside the border
}

%border_radius-circle {
  @extend %border_radius;
  border-radius: 50%; // Just make sure, as I'm sure you will, that width === height or it won't actually be a circle!
}

%border_radius-lozenge {
  @extend %border_radius;
  border-radius: $border_radius-lozenge; // Jazzy little fix for creating a lozenge for an element of unknown size
}

%border_radius-std {
  @extend %border_radius;
  border-radius: $border_radius-std;
}

%border_radius-small {
  @extend %border_radius;
  border-radius: $border_radius-sml;
}

%border-radius-large {
  @extend %border_radius;
  border-radius: $border_radius-lrg;
}