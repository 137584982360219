@import "../../scss/config/variables/all";

.footer{
    width:100%;
    border-top:solid 1px white;
    margin-top:100px;
    padding: 35px 0;
    .container{
        width:100%;
        margin:0 auto;
        padding: 0 50px;
        a{
            text-decoration: none;
            font-size: 18px;
            color: white;
            transition: 0.4s;
            &:hover{
                color: #4FFBE7;
            }
        }
    }
}
