%theme-mako {
  background-color: $clr_faze-mako;
  color: $clr_weiss;
}

%theme-gull {
  background-color: $clr_faze-gull;
  color: $clr_weiss;
}

%theme-lochinvar {
  background-color: $clr_faze-lochinvar;
  color: $clr_weiss;
}

%theme-gradient {
  background: linear-gradient(35deg, $clr_mineshaft 25%, $clr_faze-mako 95%);
  color: $clr_weiss;
}

%theme-beauty {
  background-color: $clr_faze-beauty;
  color: $clr_weiss;
}

%theme-sienna {
  background-color: $clr_faze-sienna;
  color: $clr_weiss;
}

%theme-goldenrod {
  background-color: $clr_faze-goldenrod;
  color: $clr_weiss;
}

%theme-lochinvar {
  background-color: $clr_faze-lochinvar;
  color: $clr_weiss;
}

%theme-bud {
  background-color: $clr_faze-bud;
  color: $clr_weiss;
}

%theme-alto {
  background-color: $clr_faze-alto;
  color: $clr_weiss;
}

%theme-otla {
  background-color: $clr_faze-otla;
  color: $clr_weiss;
}
