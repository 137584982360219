@import "../../../scss/config/all";

.study-dd {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  gap: 1rem;
  .label {
    font-family: $font_face-main;
  }

  .fancy-select {
    select{
      background-color: transparent;
      &:hover, &:focus {
        background-color: transparent;
        cursor: pointer;
      }
    }
    &:after {
      z-index: -1;
    }
  }
}