.navigation {
    @import '../../../scss/config/all';
    @import '../../../scss/components/masthead-link.scss';

    .navigation_list {
        @extend %semantic-list;
        @extend %horizontal-list;

        li {

            a {
                @extend %masthead-link;
            }
        }
    }


}

.navigation .navigation_list li a{
    font-family: Roboto;
    font-size: 19px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
}

.navigation .navigation_list li a.active{
    &:after{
        display: none;
    }
    span{
        text-decoration: underline;
    }
}

.navigation {
    margin-left: 100PX;
}
