%masthead-link {
  overflow: hidden;
  position: relative;
  display: block;
  text-align: center;
  text-decoration: none;
  @extend %padding-vertical-narrow;
  @extend %padding-horizontal-narrow;
  @extend %junior;
  color: $clr_faze-mandy;
  transition: $ease-swoosh $speed-std;

  &:focus,
  &:hover {
    background-color: $clr_faze-alto;
  }

  &:focus {
    outline: none;
  }

  &:after {
    content: "";
    display: block;
    width: 0;
    height: $border_width-thin;
    background: white;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: $ease-swoosh $speed-std;
  }

  &.active{
    color: $clr_faze-nurse;
    &:after{
      width: 100%;
    }
  }

  span,
  svg {
    display: inline-block;
    vertical-align: middle;
  }

  svg {
    width: 0.8em;
    height: 0.8em;
    margin-right: 0.8em;
  }
}
