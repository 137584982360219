// Icon only button styles
%button-icon-only {
  --buttonDimension: 1rem;
  @extend %border_radius-circle;
  @extend %dead-center;
  font-size: 0;
  vertical-align: middle;
  width: var(--buttonDimension);
  height: var(--buttonDimension);

  svg {
    width: calc(var(--buttonDimension) * 0.6);
    height: calc(var(--buttonDimension) * 0.6);
  }

  &:not(.button-disabled) {

    @each $name,
    $color in $themes {
      &[data-theme="#{$name}"] {
        @include button-default(map-get($themes, #{$name}));
      }
    }
  }

  &.button-large {
    @extend %button-icon-only-large;
  }

  &.button-standard {
    @extend %button-icon-only-standard;
  }

  &.button-small {
    @extend %button-icon-only-small;
  }
}

%button-icon-only-small {
  --buttonDimension: 1.5rem;
}

%button-icon-only-standard {
  --buttonDimension: 2.25rem;
}

%button-icon-only-large {
  --buttonDimension: 3rem;
}