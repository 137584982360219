$icon_translate: translate(-100%, -50%);

%input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  outline: none;
  border: 0;
}

%input-border {
  border: $border_width-thin solid $clr_faze-mako;
  margin: $border_width-thin;
}

%input-padding {
  padding-top: 0.5em;
  padding-right: 1em;
  padding-bottom: 0.5em;
  padding-left: 1em;
}

%label_text {
  @extend %junior;
  font-weight: 500;
  padding: 0.5rem 0;
  display: block;
}

%input_description {
  @extend %junior;
  font-size: 0.75rem;
  line-height: 1.25em;
  display: block;
}

%input-validation-success {
  @extend %theme-success;

  + [class^="icon-"] {
    opacity: 1;
    right: 0;
    transform: $icon_translate scale(1);
  }
}

%input-validation-failure {
  @extend %theme-failure;

  + [class^="icon-"] + [class^="icon-"] {
    opacity: 1;
    right: 0;
    transform: $icon_translate scale(1);
  }
}

label,
.label_text {
  @extend %label_text;
  text-transform: capitalize;
}

.label_disclaimer {
  padding: 0.5rem 0;
  display: block;
  line-height: 1em;
  text-transform: none;
  font-weight: 300;
}

%input-default {

  // Apply this to the label

  .input_container {
    display: block;
    position: relative;

    input,
    select,
    textarea {
      @extend %input;
      @extend %input-border;
      @extend %input-padding;
      @extend %richie;
      @extend %theme-mineshaft;
      @extend %border_radius-std;
      line-height: 1.5rem;
      display: block;
      width: 100%;
      box-shadow: none !important;
      transition: 0.5s;
      cursor: text;
      // &[data-pristine="true"] {}

      &:hover,
      &:focus {
        background-color: rgba(255,255,255,0.2);
      }

      &:disabled{
        opacity: 0.8;
        cursor: no-drop;
        &:hover,
        &:focus {
          background-color: rgba(255,0,0,0.1);
        }
      }


      // Removed temporarily as need to edit
      //&[data-pristine="false"] {
      //  &:invalid:not(:focus) {
      //    @extend %input-validation-failure;
      //  }
      //  &:valid:not(:focus) {
      //    @extend %input-validation-success;
      //  }
      //}
    }

    textarea {
      resize: none;
    }

    [class^="icon-"] {
      position: absolute;
      top: 1.5rem;
      right: -50000px;
      transform: $icon_translate scale(0);
      opacity: 0;
      transition: opacity $ease-main $speed-fast, transform $ease-main $speed-fast;

      &.icon-success {
        color: $clr_success;
      }

      &.icon-failure {
        color: $clr_failure;
      }
    }

  }

  .fancy-select {
    display: block;
    padding: 0;
    position: relative;
    z-index: 0;

    &:after {
      content: "▼";
      font-size: inherit;
      color: $clr_silverado;
      position: absolute;
      top: 50%;
      right: 0.1em;
      z-index: 0;
      transform: translateY(-50%);

      option {
        color: $clr_nightshift;
      }

    }

    &.disabled {
      &:after {
        display: none;
      }
    }
  }


  .input_description {
    @extend %input_description;
  }

  .form-message {
    @extend %form-message;
  }
}

::-webkit-input-placeholder {
  // Chrome/Opera/Safari
  font-family: $font_face-utility;
  font-size: 1em;
  color: $clr_boulder;
}

::-moz-placeholder {
  // Firefox 19+
  font-family: $font_face-utility;
  font-size: 1em;
  color: $clr_boulder;
}

:-ms-input-placeholder {
  // IE 10+
  font-family: $font_face-utility;
  font-size: 1em;
  color: $clr_boulder;
}

:-moz-placeholder {
  // Firefox 18-
  font-family: $font_face-utility;
  font-size: 1em;
  color: $clr_boulder;
}

.website-response-answer{
  color: #969696;
  font-size: 13pt;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.input-wrapper {
  max-height: 41px;
  button {
    border:1px solid transparent;
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;

    &:after {
      content: "X";
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      background-color: $clr_faze-mako;
      z-index:99;
      top: 13px;
      right: 10px;
      border-radius: 50%;
      text-align: center;
      color: white;
      font-weight: bold;
      font-size: 12px;
      cursor: pointer;
    }
  }
}