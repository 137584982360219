%button-transition {
  transition: $ease-main $speed-fast;
}

@mixin button-default($buttonColor) {
  @extend %button-transition;
  background-color: $buttonColor;
  border-color: $buttonColor;
  color: setDynamicInvertColor($buttonColor);

  &:not(.button-disabled):hover {
    $button-hover-color: darken($buttonColor, $shade-percentage);
    background-color: rgba($button-hover-color, 1);
    border-color: rgba($button-hover-color, 1);
    color: setDynamicInvertColor($button-hover-color);
  }
}

@mixin button-ghost($buttonColor) {
  @extend %button-transition;
  background-color: transparent;
  color: $buttonColor;
  border-color: $buttonColor;

  &:not(.button-disabled):hover {
    $button-hover-color: darken($buttonColor, $shade-percentage);
    background-color: rgba($button-hover-color, 1);
    border-color: rgba($button-hover-color, 1);
    color: setDynamicInvertColor($button-hover-color);
  }
}
