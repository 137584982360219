$shadow-offset-x: -0.125rem;
$shadow-offset-y: 0.125rem;
$shadow-blur: 0.25rem;
$shadow-opacity: 0.33;

$box-shadow: $shadow-offset-x $shadow-offset-y $shadow-blur rgba($clr_nightshift, $shadow-opacity);

$text-shadow: $shadow-offset-x $shadow-offset-y $shadow-blur rgba($clr_nightshift, $shadow-opacity);

%box-shadow {
  box-shadow: $box-shadow;
}

%text-shadow {
  text-shadow: $text-shadow;
}

%dynamic-box-shadow {
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: $shadow-offset-y;
    left: $shadow-offset-x;
    z-index: -1;
    background: inherit;
    filter: blur($shadow-blur);
    opacity: $shadow-opacity;
  }
}
