// A variety of usual shades of gray

$clr_nightshift: #000000;
$clr_mineshaft : #2F3137;
$clr_tuatara: #7f7f7f;
$clr_boulder : #a4a4a4;
$clr_silverado : #d8d8d8;
$clr_alabaster : #f5f5f5;
$clr_moonlight : #f7f7f7;
$clr_weiss : #ffffff;
