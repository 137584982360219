.flex-grid {
  display: flex;
  justify-content: space-between;
}

.flex-col-half {
  width: 50%;
}

.flex-col-third {
  width: calc(100% / 3);
}

.flex-col-quarter {
  width: 25%;
}
