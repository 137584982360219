.loading-spinner-element {
  animation: loadingSpinnerRotate linear 600ms;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}

@keyframes loadingSpinnerRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading_container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000000;
  top:0;
  left: 0;
  margin-top: 3em;
}
