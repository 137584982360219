%central-column {
  max-width: $max-width;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: $max-width) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

%dead-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

%joined-elements {
  overflow: hidden;
  border-top: $border_width-thin solid $clr_boulder;
  border-bottom: $border_width-thin solid $clr_boulder;

  &:first-of-type() {
    border-left: $border_width-thin solid $clr_boulder;
    border-top-left-radius: 500rem;
    border-bottom-left-radius: 500rem;
  }

  &:last-of-type() {
    border-right: $border_width-thin solid $clr_boulder;
    border-top-right-radius: 500rem;
    border-bottom-right-radius: 500rem;
  }
}